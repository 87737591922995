<script setup>
import { ref } from "vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import NavLink from "@/Components/NavLink.vue";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";
import { Link } from "@inertiajs/vue3";

const showingNavigationDropdown = ref(false);
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <div class="flex-grow bg-gray-100">
      <nav class="bg-white border-b border-gray-100">
        <!-- Primary Navigation Menu -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex">
              <!-- Logo -->
              <div class="shrink-0 flex items-center">
                <Link :href="route('home')">
                  <ApplicationLogo
                    class="block h-9 w-auto fill-current text-gray-800"
                    aria-label="Go to the homepage"
                  />
                </Link>
              </div>

              <!-- Navigation Links -->
              <!-- <div class="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex">
                                <NavLink :href="route('dashboard')" :active="route().current('dashboard')">
                                    Dashboard
                                </NavLink>
                            </div> -->
            </div>

            <div class="hidden sm:flex sm:items-center sm:ms-6">
              <!-- Guest Links -->
              <div class="ms-3 flex space-x-4">
                <Link
                  :href="route('feedback')"
                  class="text-gray-700 hover:text-gray-900"
                >
                  Feedback
                </Link>
                <Link
                  :href="route('about')"
                  class="text-gray-700 hover:text-gray-900"
                >
                  About
                </Link>
                <!-- <Link
                  :href="route('login')"
                  class="text-gray-700 hover:text-gray-900"
                >
                  Login
                </Link>
                <Link
                  :href="route('register')"
                  class="text-gray-700 hover:text-gray-900"
                >
                  Register
                </Link> -->
              </div>
            </div>

            <!-- Hamburger -->
            <div class="-me-2 flex items-center sm:hidden">
              <button
                @click="showingNavigationDropdown = !showingNavigationDropdown"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    :class="{
                      hidden: showingNavigationDropdown,
                      'inline-flex': !showingNavigationDropdown,
                    }"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                  <path
                    :class="{
                      hidden: !showingNavigationDropdown,
                      'inline-flex': showingNavigationDropdown,
                    }"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div
          :class="{
            block: showingNavigationDropdown,
            hidden: !showingNavigationDropdown,
          }"
          class="sm:hidden"
        >
          <div class="pt-2 pb-3 space-y-1">
            <!-- <ResponsiveNavLink
              :href="route('dashboard')"
              :active="route().current('dashboard')"
            >
              Dashboard
            </ResponsiveNavLink> -->
            <ResponsiveNavLink
              :href="route('feedback')"
              :active="route().current('feedback')"
            >
              Feedback
            </ResponsiveNavLink>
            <ResponsiveNavLink
              :href="route('about')"
              :active="route().current('about')"
            >
              About
            </ResponsiveNavLink>
          </div>

          <!-- Responsive Guest Options -->
          <!-- <div class="pt-4 pb-1 border-t border-gray-200">
            <div class="mt-3 space-y-1">
              <ResponsiveNavLink :href="route('login')">
                Login
              </ResponsiveNavLink>
              <ResponsiveNavLink :href="route('register')">
                Register
              </ResponsiveNavLink>
            </div>
          </div> -->
        </div>
      </nav>

      <!-- Page Heading -->
      <header class="bg-white shadow" v-if="$slots.header">
        <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <slot name="header" />
        </div>
      </header>

      <!-- Page Content -->
      <main class="mx-4">
        <slot />
      </main>
    </div>
    <footer class="bg-white py-4 mt-auto">
      <div class="text-center text-gray-800">
        © {{ new Date().getFullYear() }} Horsley Software LLC. Made with ❤️ by
        <a
          href="https://horsleysoftware.llc"
          class="text-indigo-800 font-bold hover:underline"
          >Horsley Software LLC.</a
        >
      </div>
    </footer>
  </div>
</template>
